import {cva} from 'class-variance-authority';

export const BlogConversionWrapperStyles = cva('relative overflow-hidden', {
  variants: {
    mode: {
      default: ['bg-marketingBg'],
      green: ['bg-blog-bannerBg py-10 tablet-xl:py-20'],
      purple: ['bg-partners-bannerBg py-10 tablet-xl:py-20'],
      enterprise: [
        'bg-enterprise-blog-bg conversion pb-10 pt-10 tablet-xl:pb-16 tablet-xl:pt-20 -mt-12 flex justify-center',
      ],
      engineering: [
        'bg-engineering-banner-bg conversion pb-10 pt-10 tablet-xl:pb-16 tablet-xl:pt-20 flex justify-center',
      ],
      partners: [
        'bg-partners-conversionBg',
        'bg-no-repeat tablet-xl:bg-partners-bottom-converion',
        'tablet-xl:bg-cover tablet-xl:bg-center',
        'bg-partners-bottom-converion-mobile bg-contain bg-left-bottom',
      ],
    },
  },
});

export const BlogConversionStyles = cva(
  ['container relative flex flex-col items-center text-center'],
  {
    variants: {
      mode: {
        default: [
          'pt-16 tablet-xl:pt-12 pb-12 px-[40px] tablet-xl:px-[130px] desktop:px-[210px]',
        ],
        green: ['py-10 tablet-xl:py-16'],
        purple: ['py-10 tablet-xl:py-16'],
        enterprise: [
          'py-10 tablet-xl:py-16 px-0 !mx-0 tablet:!mx-[var(--margin)]',
          '[&_a]:btn-primary [&_a]:text-link-light [&_a:hover]:text-link-light [&_a:focus]:text-link-light [&_a]:tablet:py-[14px] [&_a]:tablet:px-[26px] font-faktum',
        ],
        engineering: [
          'py-10 tablet-xl:py-16 px-0 desktop:px-[15%] tablet:!mx-[var(--margin)] [&_a]:px-16',
        ],
        partners: ['py-[54px] tablet-xl:py-32'],
      },
    },
  },
);

export const HeadingStyles = cva(
  ['uppercase font-medium font-aktivgroteskextended'],
  {
    variants: {
      mode: {
        default: ['mb-2 text-2xl tablet:text-3xl max-w-[680px]'],
        green: [
          'mb-2 text-[28px] tablet-xl:text-[42px] desktop:text-[48px] max-w-[600px] tablet-xl:mb-6',
          'leading-[36px] tablet-xl:leading-[46px] desktop:leading-[56px]',
        ],
        purple: [
          'text-white mb-4 max-w-[820px]',
          'text-[28px] tablet-xl:text-[42px] desktop:text-[48px] tablet-xl:mb-9',
          'leading-[36px] tablet-xl:leading-[46px] desktop:leading-[56px]',
        ],
        enterprise: [
          'text-section-dark-text mb-2 tablet:mb-4 normal-case font-normal font-faktum',
          'text-[40px] tablet:text-[56px]',
          'leading-[48px] tablet:leading-[60px]',
        ],
        engineering: [
          'text-[#120937] mb-2 tablet:mb-4 normal-case font-medium font-aktivgroteskextended',
          'text-5xl uppercase max-w-screen-md',
          'leading-[48px] tablet:leading-[60px]',
        ],
        partners: [
          'normal-case font-light text-white font-sans',
          'leading-[48px] tablet-xl:leading-[72px]',
          'text-[42px] tablet-xl:text-[64px]',
          'max-w-[800px] mb-9',
        ],
      },
    },
  },
);

export const SubheadStyles = cva(['max-w-[680px]'], {
  variants: {
    mode: {
      default: ['text-lg text-sm mb-4'],
      green: ['text-body-base tablet-xl:text-body-lg mb-2 tablet-xl:mb-9'],
      purple: ['text-body-base tablet-xl:text-body-lg mb-2 tablet-xl:mb-9'],
      enterprise: [
        'text-section-dark-text text-lg tablet:text-[22px] mb-4 tablet:mb-6 px-[var(--margin)] !font-shopifysans',
      ],
      engineering: [
        'text-[#120937] text-lg tablet:text-[22px] mb-4 tablet:mb-6 max-w-fit !font-shopifysans',
      ],
      partners: '',
    },
  },
});

export const ButtonGroupStyles = cva(['text-base relative z-20'], {
  variants: {
    mode: {
      default: [],
      green: [],
      purple: [
        '[&_a]:bg-[#4BFE85] [&_a]:text-black [&_a]:tablet-xl:w-[275px]',
        '[&_a]:hover:bg-[#62ba6b] [&_a]:hover:text-black',
        '[&_a]:active:bg-[#00740B] [&_a]:active:text-black',
        '[&_a]:focus:bg-[#00740B] [&_a]:focus:text-black',
      ],
      enterprise: [],
      engineering: ['[&_a]:md:w-[275px]'],
      partners: [
        '[&_a]:bg-partners-conversionButton [&_a]:text-partners-conversionButtonText',
        '[&_a]:font-ibmmono [&_a]:text-base [&_a]:font-medium',
        '[&_a]:hover:bg-[#62ba6b] [&_a]:hover:text-black',
        '[&_a]:px-5 [&_a]:py-[10px]',
      ],
    },
  },
});
