import type {ReactNode} from 'react';
import type {VariantProps} from 'class-variance-authority';
import {cx} from 'class-variance-authority';

import Typography from '@/components/base/elements/Typography/Typography';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import {type SupportedComponentTypes} from '@/utils/hoc/withRichField';
import {useTranslations} from '@/hooks/useTranslations';

import {
  BlogConversionWrapperStyles,
  BlogConversionStyles,
  HeadingStyles,
  SubheadStyles,
  ButtonGroupStyles,
} from './styles';

interface BlogConversionProps
  extends VariantProps<typeof BlogConversionStyles> {
  heading: string;
  subhead?: string;
  buttonHref: string;
  buttonText: string;
  lightButton?: {text: string; href: string; external?: boolean};
  disclaimer?: string;
  children?: ReactNode;
  headingAs?: SupportedComponentTypes;
  buttonSize?: 'small' | 'large';
  conversionWrapperStyles?: string;
  external?: boolean;
  showButtons?: boolean;
  сomponentName?: string;
}

export const BlogConversion = ({
  heading,
  subhead,
  buttonHref,
  buttonText,
  lightButton,
  children,
  disclaimer,
  conversionWrapperStyles = '',
  mode = 'default',
  headingAs = 'span',
  buttonSize = 'large',
  external,
  showButtons = true,
  сomponentName,
}: BlogConversionProps) => {
  const {localizePath} = useTranslations();
  const buttons = [
    {href: buttonHref, text: buttonText, size: buttonSize, external: external},
    ...(lightButton
      ? [
          {
            href:
              lightButton.href?.includes('help.shopify.com') ||
              lightButton?.external
                ? lightButton?.href
                : localizePath(lightButton?.href),
            size: buttonSize,
            text: lightButton.text,
            external: lightButton.external,
          },
        ]
      : []),
  ];

  return (
    <div
      className={BlogConversionWrapperStyles({mode})}
      data-component-name={сomponentName}
    >
      <div
        className={cx(BlogConversionStyles({mode}), conversionWrapperStyles)}
      >
        <Typography as={headingAs} className={HeadingStyles({mode})}>
          {heading}
        </Typography>
        {subhead && (
          <Typography className={SubheadStyles({mode})}>{subhead}</Typography>
        )}
        {showButtons && (
          <ButtonGroup
            className={ButtonGroupStyles({mode})}
            buttons={buttons}
            center
          />
        )}
        {disclaimer && (
          <Typography className="text-sm mt-2">{disclaimer}</Typography>
        )}
        {children}
      </div>
    </div>
  );
};
